import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustmoreService } from '../app/service/custmore.service';
declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  customerId: any;
  numberOfIssue: any;
  issuesarray: any[];
  newissuesarray: any[];
  responseMessage: any;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private custmoreService: CustmoreService, ) { }

  ngOnInit() {

  }


  submitcall(obj) {
    debugger;
    this.issuesarray = [];
    this.newissuesarray = [];
    this.issuesarray.push(this.numberOfIssue);
    this.newissuesarray.push(this.issuesarray);
    obj = {
      "customerId": this.customerId,
      "numberOfIssue": this.newissuesarray
    }
    this.custmoreService.submitdetails(obj).subscribe(
      data => {
        this.responseMessage = data;
        jQuery('#messagePopUp').modal({ backdrop: 'static', keyboard: false });
      },
    )
  }
  clearinputs() {
    this.customerId = '';
    this.numberOfIssue = '';
    jQuery('#messagePopUp').modal('hide');
  }

}