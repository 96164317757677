import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

const WEB_API = environment.URL;
@Injectable({
  providedIn: 'root'
})

export class CustmoreService {

  // private detailssubmit = "http://3.133.187.108:8080/aidemo/getPrediction";

  constructor(private http: HttpClient) { }

  submitdetails(obj) {
    debugger;
    return this.http.post<any>(WEB_API, obj)
  }


}









